export enum GapValues {
  FOUR = 0.5,
  EIGHT = 1,
  TWELVE = 1.5,
  SIXTEEN = 2,
  TWENTY = 2.5,
  TWENTYFOUR = 3,
  TWENTYEIGHT = 3.5,
  THIRTYTWO = 4,
  THIRTYSIX = 4.5,
  FORTY = 5,
  FORTYFOUR = 5.5,
  FORTYEIGHT = 6,
  FIFTYTWO = 6.5,
  FIFTYSIX = 7,
  SIXTY = 7.5,
  SIXTYFOUR = 8,
  SIXTYEIGHT = 8.5,
  SEVENTYTWO = 9,
  SEVENTYSIX = 10,
  EIGHTY = 10.5,
  EIGHTYFOUR = 11,
  EIGHTYEIGHT = 11.5,
  NINETYTWO = 12,
}
